import React from 'react';

class DeviceDbUsage extends React.Component {
    eventsTableSize() {
        return this.props.usage.filter(item => item.objectName === "events")[0].sizeInBytes;
    }

    itemsEventMetadataTableSize() {
        return this.props.usage.filter(item => item.objectName === "items_event_metadata")[0].sizeInBytes;
    }

    totalDbSize() {
        return this.props.usage.filter(item => item.objectName === "total")[0].sizeInBytes;
    }

    bytesToMegabytes(valueInB) {
        return (valueInB / 1024 / 1024).toFixed(2);
    }

    render() {
        return <div>Events: {this.bytesToMegabytes(this.eventsTableSize())} MB,
            Items: {this.bytesToMegabytes(this.itemsEventMetadataTableSize())} MB<br/>Total: <strong>{this.bytesToMegabytes(this.totalDbSize())} MB</strong>
        </div>;
    }
}

export default DeviceDbUsage;