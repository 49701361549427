import React from 'react';
import ContentHeader from "../ContentHeader";
import {Auth} from "@aws-amplify/auth";
import Config from "../../config/config";
import {NavLink} from "react-router-dom";
import CommandSenderForm from "./CommandsSenderForm";
import Utils from "../../utils/utils";

class CommandsView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {user: {}, heartbeats: {statuses: []}};
    }

    componentDidMount() {
        this.fetchHeartbeats();
    }

    fetchHeartbeats() {
        Auth.currentSession().then((result) => {
            const token = result.idToken.jwtToken;

            fetch(Config.apiPath('/statuses/'), {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
                .then(response => response.json())
                .then(data => this.setState({heartbeats: data}))
        });
    }

    render() {
        return <div>
            <ContentHeader title="Commands"/>
            <div className="container-fluid">
                <section className="content">
                    <div className="row  mb-3 pr-2">
                        <div className="col-md-10">
                        </div>
                        <div className="col-md-2">
                            <button type="button" className="btn ssBackground btn-block" data-toggle="modal"
                                    data-target="#add-command-modal"><i
                                className="fas fa-plus-circle"></i> Send Command
                            </button>
                        </div>
                    </div>

                    <CommandSenderForm/>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="table-responsive text-center">
                                            <table className="table text-nowrap">
                                                <thead>
                                                <tr>
                                                    <th>Device ID</th>
                                                    <th>Device Name</th>
                                                    <th></th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.heartbeats.statuses.map((device, index) => {
                                                    return <tr key={index}>
                                                        <td className="w-25 text-left">{device.deviceId}</td>
                                                        <td className="w-25 text-left">{Utils.getDeviceName(device.deviceConfiguration)}</td>
                                                        <td className="text-left">
                                                            <NavLink to={"devices/" + device.deviceId}>
                                                                <button type="button"
                                                                        className="btn btn-block btn-primary w-25">
                                                                    Show last commands
                                                                </button>
                                                            </NavLink>
                                                        </td>
                                                    </tr>;
                                                })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </section>
            </div>
        </div>
    }
}

export default CommandsView;